@font-face {
  /* 400 */
  font-family: "PoppinsMedium";
  src: url("./font/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./font/Poppins-Light.ttf") format("truetype");
}
@font-face {
  /* 350 */
  font-family: "PoppinsRegular";
  src: url("./font/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  /* 500 */
  font-family: "PoppinsSemiBold";
  src: url("./font/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  /* 600 */
  font-family: "PoppinsBold";
  src: url("./font/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RBL";
  src: url("./font/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "RBLI";
  src: url("./font/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RL";
  src: url("./font/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RLI";
  src: url("./font/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RBD";
  src: url("./font/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "RBDI";
  src: url("./font/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RM";
  src: url("./font/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "RMI";
  src: url("./font/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RR";
  src: url("./font/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RT";
  src: url("./font/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "RTI";
  src: url("./font/Roboto-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RCBD";
  src: url("./font/RobotoCondensed-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "RCBDI";
  src: url("./font/RobotoCondensed-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RCI";
  src: url("./font/RobotoCondensed-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "RCL";
  src: url("./font/RobotoCondensed-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RCLI";
  src: url("./font/RobotoCondensed-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "RCR";
  src: url("./font/RobotoCondensed-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "명조";
  src: url("./font/NanumMyeongjo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ABEL";
  src: url("./font/Abel-Regular.woff") format("woff");
}

@font-face {
  font-family: "BBO";
  src: url("./font/BakbakOne-Regular.woff") format("woff");
}

@font-face {
  font-family: "Archivo-Regular";
  src: url("./font/Archivo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Archivo-Bold";
  src: url("./font/Archivo-Bold.ttf") format("truetype");
}

* {
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

ul {
  padding: 0;
}

html {
  font-size: 1vw;
}

/* PC 환경 */
@media screen and (min-width: 769px) {
}

/* 모바일 환경 */
@media screen and (max-width: 768px) {
}
